import React, { useEffect, useState } from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./Faculty.css";
import setting from "../../setting.json";
import { Row, Col, Button } from "react-bootstrap";

import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, CardBody, Card } from "react-bootstrap";


const schema = yup.object().shape({
    course_id: yup.string().required("Fill value"),
    type: yup.string().required("Fill value"),


});
function Faculty() {
    const [faculty, setFaculty] = useState('');
    const [coursecList, setCoursecList] = useState();

    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });


    const getAllCourse = async () => {
        await fetch(setting.api + "api/getAllCourse", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setCoursecList(u.data);

            });
    }
    const getFacultyRecord = async (data) => {

        let sr = {
            "course_id": data.course_id,
            "type": data.type,

        }
        await fetch(setting.api + "api/getFacultyRecord", {
            body: JSON.stringify(sr),
            method: "post",
            mode: "cors",
            headers: {

                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(u => {
                setFaculty(u.data);

            });
    }

    useEffect(() => {
        getAllCourse();
    }, []);


    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold">Faculty</h1>
                </div>
            </header>

            <section className="teacher-section py-3 bg_img ">
                <div className="container">

                    {/* <Row>
                        <Col></Col>
                        <Col>
                            <ButtonGroup aria-label="Basic example">
                                <Button variant="success" href="/bed">B.ED.</Button>
                                <Button variant="success" href="/deled">D.EL.ED</Button>
                            </ButtonGroup>
                        </Col>
                     <Col>
                            <Button variant="success">Success</Button>
                        </Col>
                        <Col></Col>
                    </Row> */}


                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="section-header">
                                <h3 className="title"><b>Our Teachers</b></h3>
                            </div>
                        </div>
                    </div>
                    <div className="cart-checkout-box mb-widget">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmit(getFacultyRecord)} >
                                    <Row >
                                        <Col>
                                            <Form.Group>
                                                <Form.Select aria-label="Default select example" {...register("course_id")}
                                                >
                                                    <option >Course</option>
                                                    {
                                                        coursecList && coursecList.map((a) =>
                                                            <option key={a.id} value={a.id}>{a.course}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                                {errors.course_id && <p>{errors.course_id.message}</p>}
                                            </Form.Group>
                                        </Col >
                                        <Col>
                                            <Form.Group>
                                                <Form.Select aria-label="Default select example"
                                                    {...register("type")}
                                                >
                                                    <option>Select Type</option>
                                                    <option value="1">Teaching</option>
                                                    <option value="2">Non-Teaching</option>

                                                </Form.Select>
                                                {errors.type && <p>{errors.type.message}</p>}
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Button type="submit" variant="secondary">Search</Button>

                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>

                    </div>
                    <div className="row justify-content-center text-light">

                        {faculty && faculty.map((f) =>

                            <div className="col-lg-2 col-md-2 col-sm-3 col-xs-4 teachers-bg mx-1 my-1 " style={{ backgroundColor: "rgba(131, 161, 218, .308)", color: "black" }}>
                                <img src={f.photo} className="col-12 teacher-img my-3 " alt="" />
                                <p className=" teacher-edu">
                                    <b className="teacher-name">{f.name}</b>
                                    <br />
                                    {f.qualification}
                                    <br />
                                    {f.designation}
                                    <br />
                                    {f.joindate}
                                </p>

                            </div>

                        )}

                    </div>

                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Faculty;
